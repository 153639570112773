import React from "react";

function Events({ driverFreight, events }) {
    return (
        <>
            <h5 className="mt-3 uppercase">Eventos da viagem</h5>
            <div className="overflow-auto" style={{ height: "calc( var(--heightt) - 75px )" }}>
                <table className="table-auto mt-6 w-full text-left ">
                    <thead className="border-b border-roxo_oficial">
                        <tr>
                            <th className="text-roxo_oficial">Descrição</th>
                            <th className="text-roxo_oficial">Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {events?.length > 0 &&
                                events?.map(entity => {
                                    return (
                                        <tr key={entity?.identifier}>
                                            <td>{entity?.description}</td>
                                            <td>{entity?.occuredAtFormatted}</td>
                                        </tr>
                                    );
                                })}
                            {(!events || events?.length === 0) && (
                                <tr className="text-center">
                                    <td className="text-center" colSpan="9">
                                        Sem eventos nesta viagem.
                                    </td>
                                </tr>
                            )}
                        </>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Events;
